import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "SearchTool" }
const _hoisted_2 = { class: "tag-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_ButtonSubmit = _resolveComponent("ButtonSubmit")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_DataRender = _resolveComponent("DataRender")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_Delete = _resolveComponent("Delete")!
  const _component_el_button_group = _resolveComponent("el-button-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DataRender, {
      ref: "drElement",
      onSuccess: _ctx.handleLoadSuccess
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          inline: true,
          model: _ctx.form,
          ref: "FormElement"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.formFields.field.label,
              prop: _ctx.formFields.field.prop,
              rules: _ctx.formFields.field.rule
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.form.field,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.field) = $event)),
                  placeholder: "请选择字段",
                  class: "field-select",
                  onChange: _ctx.createForm
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerFields, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.name,
                        label: item.display_name,
                        value: item.name
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"])
              ]),
              _: 1
            }, 8, ["label", "prop", "rules"]),
            (_ctx.innerWheres.length > 1)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 0,
                  label: "条件",
                  prop: "operator"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      modelValue: _ctx.form.operator,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.operator) = $event)),
                      placeholder: "请选择条件",
                      class: "where-select",
                      onChange: _ctx.handleChangeWhere
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerWheres, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.value,
                            label: item.label,
                            value: item.value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["modelValue", "onChange"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.valueComponent)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.valueComponent), {
                  operator: _ctx.form.operator,
                  key: _ctx.form.operator,
                  valueType: _ctx.form.valueType,
                  modelValue: _ctx.form.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.value) = $event)),
                  optionsProps: _ctx.innerOptionsProps,
                  options: _ctx.innerOptions,
                  type: _ctx.innerType,
                  url: _ctx.innerUrl
                }, null, 8, ["operator", "valueType", "modelValue", "optionsProps", "options", "type", "url"]))
              : _createCommentVNode("", true),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ButtonSubmit, {
                  click: _ctx.addWhere,
                  type: "primary",
                  plain: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("添加条件")
                  ]),
                  _: 1
                }, 8, ["click"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _ctx.handleSubmit,
                  disabled: _ctx.SqlService.list.length === 0 && !_ctx.RouterService.query('_search')
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("查询")
                  ]),
                  _: 1
                }, 8, ["onClick", "disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["onSuccess"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.SqlService.list, (tag, index) => {
        return (_openBlock(), _createBlock(_component_el_button_group, { key: index }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "warning",
              size: "small",
              plain: "",
              innerHTML: tag.text
            }, null, 8, ["innerHTML"]),
            _createVNode(_component_el_button, {
              type: "warning",
              size: "small",
              onClick: ($event: any) => (_ctx.handleEditTag(index)),
              plain: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Edit)
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_el_button, {
              type: "warning",
              size: "small",
              onClick: ($event: any) => (_ctx.handleRemoveTag(index)),
              plain: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Delete)
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ])
  ]))
}