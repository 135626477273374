import RouterService from '@/pk/src/service/RouterService'
import { Aim, Bell, DocumentChecked, MessageBox, Money, Notification, Odometer, Tickets, User, Coin } from '@element-plus/icons-vue'
import { markRaw } from 'vue'

export default [
  {
    path: '/operation',
    component: () => import('pk/views/sider.vue'),
    meta: {
      title: '运营管理'
    },
    children: [
      {
        path: '/operation/coupon-template',
        component: () => import('@/biz/admin/views/operation/coupon-template/index.vue'),
        meta: {
          title: '优惠券模板',
          icon: markRaw(MessageBox)
        },
        children: [
          {
            path: '/operation/coupon-template/form',
            component: () => import('@/biz/admin/views/operation/coupon-template/form.vue'),
            meta: {
              title: () => {
                return RouterService.query('id') ? '编辑优惠券模板' : '添加优惠券模板'
              },
              isForm: true
            }
          }
        ]
      },
      {
        path: '/operation/coupon',
        component: () => import('@/biz/admin/views/operation/coupon/index.vue'),
        meta: {
          title: '优惠券列表',
          icon: markRaw(Money)
        }
      },
      {
        path: '/operation/user-order',
        component: () => import('@/biz/admin/views/operation/user-order/index.vue'),
        meta: {
          title: '订单列表',
          icon: markRaw(Tickets)
        }
      },
      {
        path: '/operation/task',
        component: () => import('@/biz/admin/views/operation/task/index.vue'),
        meta: {
          title: '任务管理',
          icon: markRaw(Aim)
        },
        children: [
          {
            path: '/operation/task/form',
            component: () => import('@/biz/admin/views/operation/task/form.vue'),
            meta: {
              title: '编辑任务',
              isForm: true
            }
          }
        ]
      },
      {
        path: '/operation/task-record',
        component: () => import('@/biz/admin/views/operation/task-record/index.vue'),
        meta: {
          title: '任务记录',
          icon: markRaw(DocumentChecked)
        }
      },
      {
        path: '/operation/notify-template',
        component: () => import('@/biz/admin/views/operation/notify-template/index.vue'),
        meta: {
          title: '通知模板',
          icon: markRaw(Notification)
        },
        children: [
          {
            path: '/operation/notify-template/form',
            component: () => import('@/biz/admin/views/operation/notify-template/form.vue'),
            meta: {
              title: '编辑模板',
              isForm: true
            }
          }
        ]
      },
      {
        path: '/operation/notify',
        component: () => import('@/biz/admin/views/operation/notify/index.vue'),
        meta: {
          title: '通知记录',
          icon: markRaw(Bell)
        }
      },
      {
        path: '/operation/notify-admin-user',
        component: () => import('@/biz/admin/views/operation/notify-admin-user/index.vue'),
        meta: {
          title: '设置审核人员',
          icon: markRaw(User)
        },
        children: [
          {
            path: '/operation/notify-admin-user/form',
            component: () => import('@/biz/admin/views/operation/notify-admin-user/form.vue'),
            meta: {
              title: '编辑审核人员',
              isForm: true
            }
          }
        ]
      },
      {
        path: '/operation/report',
        component: () => import('@/biz/admin/views/operation/report/index.vue'),
        meta: {
          title: '数据报表',
          icon: markRaw(Odometer)
        }
      },
      {
        path: '/operation/operation-text-reply',
        component: () => import('@/biz/admin/views/operation/operation-text-reply/index.vue'),
        meta: {
          title: '微信文本回复',
          icon: markRaw(Odometer)
        },
        children: [
          {
            path: '/operation/operation-text-reply/form',
            component: () => import('@/biz/admin/views/operation/operation-text-reply/form.vue'),
            meta: {
              title: '编辑微信文本回复',
              isForm: true
            }
          }
        ]
      },
      {
        path: '/operation/my-salary',
        component: () => import('@/biz/admin/views/operation/my-salary/index.vue'),
        meta: {
          title: '我的收入',
          icon: markRaw(Coin)
        }
      }
    ]
  }
]
