import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: [String, Number, Object, Array],
    operator: String,
    valueType: String
  },
  watch: {
    modelValue (val) {
      this.innerValue = val
    },
    innerValue (val) {
      this.$emit('update:modelValue', val)
    }
  },
  data () {
    return {
      innerValue: '' as any
    }
  },
  created () {
    if (this.modelValue) {
      this.innerValue = this.modelValue
    } else {
      this.innerValue = (this.operator === 'between' || this.operator === 'in') ? [] : ''
    }
  }
})
