import IOService from 'pk/service/IOService'
import BaseAbstract from 'pk/abstract/BaseAbstract'

class HrResumeService extends BaseAbstract {
  modelName = 'HrResume'
  displayName = '求职'
  basePath = '/hr/resume'

  index (params: any) {
    return IOService.get('hr_resume', params)
  }

  store (params: { is_push: number; industry: number[]; city: number }) {
    return IOService.post('hr_resume', params)
  }

  show (id: number) {
    return IOService.get(`hr_resume/${id}`)
  }

  update (params: { id: string; is_push: number; industry: number[]; city: number }) {
    return IOService.put(`hr_resume/${params.id}`, params)
  }

  destroy (id: string) {
    return IOService.delete(`hr_resume/${id}`)
  }
}

export default new HrResumeService()
