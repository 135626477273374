import RouterService from '@/pk/src/service/RouterService'
import { Collection, CollectionTag } from '@element-plus/icons-vue'
import { markRaw } from 'vue'

export default [
  {
    path: '/config',
    component: () => import('pk/views/sider.vue'),
    meta: {
      title: '设置'
    },
    children: [
      {
        path: '/config/industry',
        component: () => import('@/biz/admin/views/config/industry/index.vue'),
        meta: {
          title: '行业',
          icon: markRaw(Collection)
        },
        children: [
          {
            path: '/config/industry/form',
            component: () => import('@/biz/admin/views/config/industry/form.vue'),
            meta: {
              title: () => {
                return RouterService.query('id') ? '编辑行业' : '添加行业'
              },
              isForm: true
            }
          }
        ]
      },
      {
        path: '/config/ad',
        component: () => import('@/biz/admin/views/config/ad/index.vue'),
        meta: {
          title: '广告位',
          icon: markRaw(CollectionTag)
        },
        children: [
          {
            path: '/config/ad/form',
            component: () => import('@/biz/admin/views/config/ad/form.vue'),
            meta: {
              title: () => {
                return RouterService.query('id') ? '编辑广告位' : '添加广告位'
              },
              isForm: true
            }
          }
        ]
      }
    ]
  }
]
