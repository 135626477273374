import axios from 'axios'
import CacheService from '@/service/CacheService'
import RouterService from './RouterService'
import { appApi } from './ToolsService'
import AuthService from './AuthService'
import VersionService, { Version } from './VersionService'

export interface PromiseResult {
  message: string;
  data: any;
  code?: number;
  status?: string;
  version: Version;
}

interface UrlParams {
  url: string;
  method: string;
  params?: object;
  data?: object;
}

class IOService {
  onceUrls = ['/app/getAppConfig']
  appApi = appApi + '/admin'

  constructor () {
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    axios.defaults.headers.common.Accept = 'application/json'
  }

  get (url: string, data?: object) {
    return this.ajax({
      url,
      method: 'get',
      params: data
    })
  }

  post (url: string, data: object) {
    return this.ajax({
      url,
      method: 'post',
      data
    })
  }

  put (url: string, data: object) {
    return this.ajax({
      url,
      method: 'put',
      data
    })
  }

  delete (url: string, data?: object) {
    return this.ajax({
      url,
      method: 'delete',
      data
    })
  }

  private ajax (data: any): Promise<PromiseResult> {
    axios.defaults.baseURL = this.appApi
    if (CacheService.user.get('api_token')) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + CacheService.user.get('api_token')
    }

    return new Promise((resolve, reject) => {
      axios(data)
        .then(res => res.data)
        .then(res => {
          if (res.status === 'success') {
            return this.checkVersion(data, res)
              .then(() => {
                resolve(res)
              })
          } else {
            if (res.code === 401) {
              this.notLogin()
            }
            reject(res)
          }
        })
        .catch(res => {
          if (res.response) {
            if (res.response.status === 401) {
              this.notLogin()
            }
            reject(res.response.data)
          } else {
            reject(new Error('网络错误'))
          }
        })
    })
  }

  private notLogin () {
    return AuthService.logout()
      .then(() => {
        RouterService.push('/login')
      })
  }

  private checkVersion (data: UrlParams, res: PromiseResult) {
    return Promise.resolve()
      .then(() => {
        if (!this.onceUrls.includes(data.url)) {
          return VersionService.checkAllVersion(res.version)
        }
      })
  }
}

export default new IOService()
