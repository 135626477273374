import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "tips"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    class: "FormSelect",
    label: _ctx.field.label,
    prop: _ctx.field.prop,
    placeholder: '请选择' + _ctx.field.label,
    rules: _ctx.field.rule
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_select, {
        modelValue: _ctx.innerValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
        disabled: _ctx.field.disabled,
        onChange: _ctx.onChange,
        clearable: "",
        size: _ctx.field.size,
        multiple: _ctx.field.multiple
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (v) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              value: v[_ctx.props.value],
              key: v[_ctx.props.value],
              label: v[_ctx.props.label],
              disabled: v.disabled
            }, null, 8, ["value", "label", "disabled"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue", "disabled", "onChange", "size", "multiple"]),
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            loading: true,
            size: "mini",
            class: "select-btn-loading"
          }, {
            default: _withCtx(() => [
              _createTextVNode("加载中...")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.field.tips)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.field.tips), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["label", "prop", "placeholder", "rules"]))
}