import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_time_picker = _resolveComponent("el-time-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    label: _ctx.field.label,
    prop: _ctx.field.prop,
    rules: _ctx.field.rule
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_time_picker, {
        modelValue: _ctx.innerValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
        "value-format": "HH:mm:ss",
        editable: false,
        placeholder: '请选择' + _ctx.field.label
      }, null, 8, ["modelValue", "placeholder"])
    ]),
    _: 1
  }, 8, ["label", "prop", "rules"]))
}