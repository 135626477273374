
import ConstService from 'pk/service/ConstService'
import { defineComponent } from 'vue'
import FormMixins from './FormMixins'

export default defineComponent({
  mixins: [FormMixins],
  props: {
    type: String
  },
  data () {
    return {
      props: {
        expandTrigger: 'hover',
        label: 'display_name',
        value: 'id',
        emitPath: false
      }
    }
  },
  computed: {
    formItemElement (): any {
      return this.$refs.formItemElement
    }
  },
  created () {
    if (this.type === 'city' || this.type === 'nationality') {
      this.field.props = {
        label: 'name',
        value: 'id'
      }
      if (this.type === 'city') {
        this.field.options = ConstService.getAreaOptions()
      }
      const requiredRule = (this.field.rule || []).find((res) => res.required)
      if (requiredRule) {
        requiredRule.type = 'number'
        this.field.rule = (this.field.rule || []).concat([{ validator: this.validateCityRequired }])
      }
    }
    this.innerValue = this.innerValue ? Number(this.innerValue) : 0
    Object.assign(this.props, this.field.props)
  },
  methods: {
    handleChange (value: string | number) {
      setTimeout(() => {
        this.formItemElement.clearValidate()
      }, 0)
      this.$emit('change', value)
    },
    validateCityRequired (rule: any, value: number, callback: Function) {
      if (this.innerValue) {
        callback()
      } else {
        const text = `请选择${this.field.label}`
        callback(text)
      }
    }
  }
})
