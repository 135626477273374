import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "cover-img" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageMaskContainer = _resolveComponent("ImageMaskContainer")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_plus = _resolveComponent("plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_ButtonSubmit = _resolveComponent("ButtonSubmit")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    label: _ctx.field.label,
    prop: _ctx.field.prop,
    rules: _ctx.field.rule,
    class: "FormImages"
  }, {
    default: _withCtx(() => [
      (_ctx.innerValue.length > 0)
        ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            gutter: 8,
            class: "images-container"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerValue, (img, index) => {
                return (_openBlock(), _createBlock(_component_el_col, {
                  span: 6,
                  key: index
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      (_openBlock(), _createBlock(_component_ImageMaskContainer, {
                        modelValue: _ctx.innerValue,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
                        index: index,
                        size: "auto",
                        disabled: _ctx.field.disabled,
                        key: img
                      }, null, 8, ["modelValue", "index", "disabled"]))
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.field.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ButtonSubmit, { onClick: _ctx.handleChangeModal }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_plus)
                  ]),
                  _: 1
                }),
                _createTextVNode(" 添加图片")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["label", "prop", "rules"]))
}