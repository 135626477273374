
import ValidateService from 'pk/service/ValidateService'
import { defineComponent } from 'vue'
import SearchMixins from './SearchMixins'

export default defineComponent({
  mixins: [SearchMixins],
  data () {
    return {
      field: ValidateService.genRule({
        prop: 'value',
        label: '值',
        rule: [ValidateService.required({ trigger: 'change' })]
      })
    }
  },
  methods: {
    handleChangeDateRange (date: string[]) {
      if (date) {
        this.innerValue = [date[0] + ' 00:00:00', date[1] + ' 23:59:59']
      } else {
        this.innerValue = ''
      }
    },
    handleChangeDate (date: string) {
      if (this.operator === '>') {
        this.innerValue = date + ' 23:59:59'
      } else if (this.operator === '<') {
        this.innerValue = date + ' 00:00:00'
      }
    }
  }
})
