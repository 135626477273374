import { ElMessage, ElMessageBox } from 'element-plus'

class UIService {
  toast = {
    success: (message: string) => {
      if (message) {
        ElMessage({
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: message,
          type: 'success'
        })
      }
    },
    error: (message: string) => {
      if (message) {
        ElMessage({
          showClose: true,
          dangerouslyUseHTMLString: true,
          message: message,
          type: 'error'
        })
      }
    }
  }

  alert = ({ title = '提示', content = '', ok = '确定' }: { title?: string; content: string; ok?: string }) => {
    return ElMessageBox.alert(content, title, { confirmButtonText: ok })
  }

  confirm = ({ title = '提示', content = '', ok = '确定', cancel = '取消', type = 'warning' }: { title?: string; content: string; ok?: string; cancel?: string; type?: any }) => {
    return ElMessageBox.confirm(content, title, { confirmButtonText: ok, cancelButtonText: cancel, type })
  }
}

export default new UIService()
