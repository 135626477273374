import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mask" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Close = _resolveComponent("Close")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_View = _resolveComponent("View")!
  const _component_CoverImage = _resolveComponent("CoverImage")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ImageMaskContainer", { auto: _ctx.size === 'auto' }]),
    style: _normalizeStyle({ width: _ctx.size + 'px', height: _ctx.size + 'px' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.disabled)
        ? (_openBlock(), _createBlock(_component_el_icon, {
            key: 0,
            class: "el-icon-close",
            onClick: _ctx.handleRemove
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Close)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_el_icon, {
        class: "el-icon-view",
        onClick: _ctx.handleViewImages
      }, {
        default: _withCtx(() => [
          _createVNode(_component_View)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    (typeof _ctx.innerValue === 'string')
      ? (_openBlock(), _createBlock(_component_CoverImage, {
          key: 0,
          src: _ctx.innerValue,
          zip: "360"
        }, null, 8, ["src"]))
      : (_openBlock(), _createBlock(_component_CoverImage, {
          key: 1,
          src: _ctx.innerValue[_ctx.index],
          zip: "360"
        }, null, 8, ["src"]))
  ], 6))
}