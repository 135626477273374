
import { defineComponent } from 'vue'
import FormMixins from './FormMixins'

export default defineComponent({
  mixins: [FormMixins],
  props: {
    type: {
      type: String,
      default: 'date'
    }
  },
  data () {
    return {
      valueFormat: 'YYYY-MM-DD'
    }
  },
  created () {
    if (this.type === 'datetime') {
      this.valueFormat = 'YYYY-MM-DD HH:mm:ss'
    }
  }
})
