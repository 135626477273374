
import { defineComponent } from 'vue'
import { IFormFieldItem, IFormFields } from 'pk/interface/common'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    modelValue: {
      type: Array as () => any[],
      required: true
    },
    initForm: {
      type: Object,
      required: true
    },
    fields: {
      type: Object as () => IFormFields,
      required: true
    },
    col: {
      type: Number,
      default: 2
    },
    load: {
      type: Function,
      default: () => Promise.resolve()
    }
  },
  data () {
    return {
      innerValue: this.modelValue,
      isShowDialog: false,
      innerForm: {},
      editIndex: -1,
      isLoadComplete: false
    }
  },
  methods: {
    createGroup () {
      Object.assign(this.innerForm, this.initForm)
      this.isShowDialog = true
    },
    getValue (row: any, field: IFormFieldItem | IFormFields) {
      let value = ''
      if (field.valueFormat) {
        value = field.valueFormat as string
        Object.keys(row).forEach((key) => {
          value = value.replace(key, row[key])
        })
        return value
      } else if (field.options) {
        const innerValue = row[field.prop as string]
        const props = {
          value: 'value',
          label: 'display_name'
        }
        Object.assign(props, field.props)
        const optionItem = field.options.find((res: any) => res[props.value] === innerValue)
        return optionItem[props.label]
      } else {
        const innerValue = row[field.prop as string]
        if (Array.isArray(innerValue) && (field.prop as string).includes('images')) {
          return innerValue.length + '张图片'
        } else {
          return innerValue
        }
      }
    },
    handleSubmit () {
      return Promise.resolve()
        .then(() => {
          if (this.editIndex === -1) {
            this.innerValue.push(JSON.parse(JSON.stringify(this.innerForm)))
          } else {
            Object.assign(this.innerValue[this.editIndex], this.innerForm)
          }
          this.editIndex = -1
          this.isShowDialog = false
        })
    },
    handleDelete (index: number) {
      return Promise.resolve()
        .then(() => {
          this.innerValue.splice(index, 1)
        })
    },
    handleEdit (index: number) {
      this.editIndex = index
      Object.assign(this.innerForm, this.innerValue[index])
      this.isShowDialog = true
    },
    handleClose () {
      this.editIndex = -1
    },
    handleLoadSuccess () {
      this.isLoadComplete = true
    }
  }
})
