import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tips"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    class: "FormInput",
    label: _ctx.field.label,
    prop: _ctx.field.prop,
    rules: _ctx.field.rule,
    "label-width": _ctx.field.labelWidth
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_input, {
        style: _normalizeStyle({ width: _ctx.field.width + 'px' }),
        modelValue: _ctx.innerValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
        type: _ctx.innerType,
        placeholder: _ctx.field.placeholder ? _ctx.field.placeholder : '请输入' + _ctx.field.label,
        disabled: _ctx.field.disabled,
        readonly: _ctx.field.readonly,
        "show-word-limit": _ctx.maxLength && _ctx.maxLength > 0,
        autosize: _ctx.autosize ? { minRows: 1.2 } : false,
        maxlength: _ctx.maxLength,
        clearable: ""
      }, null, 8, ["style", "modelValue", "type", "placeholder", "disabled", "readonly", "show-word-limit", "autosize", "maxlength"]),
      _renderSlot(_ctx.$slots, "tips", {}, () => [
        (_ctx.field.tips)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.field.tips), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["label", "prop", "rules", "label-width"]))
}