
import { defineComponent } from 'vue'
import { formatDate } from '../../service/ToolsService'
import ChartService from 'pk/service/ChartService'

export default defineComponent({
  props: {
    type: String,
    title: String,
    fields: {
      type: Array as () => { key: string; name: string }[],
      required: true
    },
    seriesParams: {
      type: Object
    }
  },
  data () {
    return {
      category: [] as string[],
      componentName: '',
      series: [] as { [x: string]: any }[],
      search: {
        date: formatDate(undefined, 'Y-m'),
        type: 'month'
      }
    }
  },
  computed: {
    dataElement (): any {
      return this.$refs.dataElement
    }
  },
  created () {
    if (this.type === 'line') {
      this.componentName = 'ChartLine'
    } else if (this.type === 'bar') {
      this.componentName = 'ChartBar'
    }
  },
  methods: {
    handleLoad () {
      return Promise.resolve()
        .then(() => {
          if (this.search.type === 'month') {
            return ChartService.getCurrentMonthData(this.search.date)
          } else {
            return ChartService.getCurrentYearData(this.search.date)
          }
        })
        .then((res) => {
          this.category = this.resolveCategory(res.data)
          this.series = this.resolveSeries(res.data)
        })
    },
    handleChange (params: { date: string; type: string }) {
      Object.assign(this.search, params)
      this.dataElement.reload()
    },
    resolveCategory (data: { [x: string]: any }[]) {
      if (this.search.type === 'month') {
        return data.map((res) => {
          const obj = res.stat_date.split('-')
          return `${Number(obj[1])}月${Number(obj[2])}日`
        })
      } else {
        return ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
      }
    },
    resolveSeries (data: { [x: string]: any }[]) {
      if (this.search.type === 'month') {
        return this.fields.map((res) => {
          return {
            _key: res.key,
            data: data.map((item) => item.stat_data[res.key]),
            name: res.name,
            ...(this.seriesParams || {})
          }
        })
      } else {
        return this.fields.map((res) => {
          return {
            _key: res.key,
            name: res.name,
            data: this.__resolveYearData(data, res.key),
            ...(this.seriesParams || {})
          }
        })
      }
    },
    __resolveYearData (data: { [x: string]: any }[], key: string) {
      return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((month) => {
        const val = data.filter((item) => item.stat_date.includes(`${this.search.date}-${month}`))
          .reduce((acc, item) => {
            acc = acc + (item.stat_data[key] * 100)
            return acc
          }, 0)
        return val / 100
      })
    }
  }
})
