import IOService from './IOService'
import RouterService from './RouterService'

class ChartService {
  createTodayData () {
    return IOService.get('chart/createTodayData', { toDay: RouterService.query('toDay') })
  }

  getCurrentMonthData (date: string) {
    return IOService.get('chart/getCurrentMonthData', { date })
  }

  getCurrentYearData (date: string) {
    return IOService.get('chart/getCurrentYearData', { date })
  }

  getPayRatio () {
    return IOService.get('chart/getPayRatio')
  }
}

export default new ChartService()
