import RouterService from 'pk/service/RouterService'
import FormFactory from './FormFactory'

interface ListItem {
  path: string;
  id: number;
  service: FormFactory;
}

class PageFormService {
  private list: ListItem[] = []

  public add (item: { id: number; service: FormFactory | any }) {
    const path = RouterService.getPath()
    const listItem = this.list.find((res) => res.id === item.id)
    if (listItem) {
      listItem.service = item.service
    } else {
      this.list.push({
        ...item,
        path
      })
    }
  }

  public del (id: number) {
    this.list = this.list.filter((res) => res.id !== id)
  }

  public get (id: number) {
    const listItem = this.list.find((res) => res.id === id)
    return listItem ? listItem.service : null
  }

  public destroy (path = RouterService.getPath()) {
    this.list = this.list.filter((res) => res.path !== path)
  }
}

export default new PageFormService()
