
import ConstService from 'pk/service/ConstService'
import { getDeepValue } from 'pk/service/ToolsService'
import { defineComponent } from 'vue'
import TableMixins from './TableMixins'

export default defineComponent({
  mixins: [TableMixins],
  props: {
    color: String,
    isHtml: Boolean
  },
  computed: {
    innerClassName () {
      const def = 'TableText TableText-' + this.prop
      return this.className ? def + ' ' + this.className : def
    }
  },
  methods: {
    getValue (row: any, prop?: string) {
      const field = prop || this.prop as string
      if (Array.isArray(row[field])) {
        return this.getArrayValue(row)
      } else if (row[field] && row[field].text && row[field].color) {
        return row[field].text
      } else {
        let value = getDeepValue(field, row)
        // ''+ 表示转字符串类型 如果value是整型，0的话前端会显示 --
        if (typeof value === 'number') {
          value = '' + value
        }
        return field ? (value || '--') : ''
      }
    },
    getArrayValue (row: any) {
      const field = this.prop as string
      const label = this.props.label || 'display_name'
      return row[field].map((item: any) => {
        if (typeof item === 'string') {
          return item
        } else {
          return item[label]
        }
      }).join('、')
    },
    getColor () {
      return ConstService.getColor(this.color || '')
    }
  }
})
