
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    load: {
      type: Function,
      default: () => Promise.resolve()
    }
  },
  methods: {
    handleLoad () {
      return this.load()
    },
    reload () {
      return (this.$refs.drElement as any).reload()
    },
    handleLoadSuccess () {
      this.$emit('success')
    }
  }
})
