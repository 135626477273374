import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "cover-img" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_text = _resolveComponent("el-text")!
  const _component_Close = _resolveComponent("Close")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_plus = _resolveComponent("plus")!
  const _component_ButtonSubmit = _resolveComponent("ButtonSubmit")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    label: _ctx.field.label,
    prop: _ctx.field.prop,
    rules: _ctx.field.rule,
    class: "FormFiles"
  }, {
    default: _withCtx(() => [
      (_ctx.innerValue.length > 0)
        ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            gutter: 8,
            class: "images-container"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerValue, (file, index) => {
                return (_openBlock(), _createBlock(_component_el_col, {
                  span: 24,
                  key: index
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_el_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_text, { truncated: "" }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", {
                                title: file.name
                              }, _toDisplayString(file.name), 9, _hoisted_2)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_icon, {
                            class: "el-icon-close",
                            onClick: ($event: any) => (_ctx.handleRemove(index))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Close)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.field.disabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_ButtonSubmit, { onClick: _ctx.handleChangeModal }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_plus)
                  ]),
                  _: 1
                }),
                _createTextVNode(" 添加文件")
              ]),
              _: 1
            }, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["label", "prop", "rules"]))
}