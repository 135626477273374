
import { defineComponent } from 'vue'
import FormMixins from './FormMixins'

export default defineComponent({
  mixins: [FormMixins],
  props: {
    autosize: Boolean,
    type: String
  },
  data () {
    return {
      maxLength: null as null|number,
      innerType: this.type
    }
  },
  created () {
    const rule = (this.field.rule || []).find((res) => res.max || res.len)
    if (rule) {
      this.maxLength = rule.max || rule.len
    }
    this.innerValue = typeof this.innerValue === 'string' ? this.innerValue : JSON.stringify(this.innerValue)
    this.innerType = this.innerType ? this.innerType : (this.autosize ? 'textarea' : 'text')
  }
})
