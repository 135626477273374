import { defineComponent } from 'vue'
import PageTableService from './PageTableService'

export default defineComponent({
  props: {
    prop: {
      type: String,
      default: '_action'
    },
    label: {
      type: String,
      default: '操作'
    },
    width: Number,
    minWidth: Number,
    align: String,
    className: String,
    props: {
      type: Object,
      default: () => {
        return {
          label: 'display_name',
          value: 'value'
        }
      }
    }
  },
  inject: ['tableId'],
  data () {
    return {
      tableService: PageTableService.get(this.tableId as unknown as number)
    }
  }
})
