import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_DataRender = _resolveComponent("DataRender")!

  return (_openBlock(), _createBlock(_component_DataRender, {
    ref: "DataRenderElement",
    class: "RouterViewContainer"
  }, {
    default: _withCtx(() => [
      (_ctx.$route.matched.length > _ctx.deep)
        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
        : _renderSlot(_ctx.$slots, "default", { key: 1 })
    ]),
    _: 3
  }, 512))
}