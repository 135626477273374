
import { IFormFieldItem } from 'pk/interface/common'
import { OptionItem } from 'pk/service/ConstService'
import { defineComponent } from 'vue'
import FormFactory from './FormFactory'
import PageFormService from './PageFormService'

export default defineComponent({
  props: {
    field: {
      type: Object as () => IFormFieldItem,
      required: true
    },
    modelValue: {
      type: [Object as () => string | string[] | number[], String],
      default: ''
    },
    type: {
      type: String,
      default: 'value-array'
    }
  },
  inject: ['formId'],
  watch: {
    modelValue (val) {
      this.setInnerValue(val)
    },
    innerValue (newVal, oldVal) {
      this.setValue(newVal, oldVal)
    }
  },
  data () {
    return {
      props: {
        value: 'value',
        label: 'display_name'
      },
      options: [] as OptionItem[],
      innerValue: [] as string[] | number[],
      formService: PageFormService.get(this.formId as unknown as number) as FormFactory
    }
  },
  created () {
    this.setInnerValue(this.modelValue)
    Object.assign(this.props, this.field.props)
    if (this.type === 'label-array' || this.type === 'label-string') {
      Object.assign(this.props, { value: this.props.label, label: this.props.label })
    }
    if (this.field.options) {
      this.options = this.field.options
    } else if (this.formService.infoService) {
      this.options = this.formService.infoService.getOptions(this.field.prop)
    }
  },
  methods: {
    setInnerValue (value: string | number[] | string[]) {
      let innerValue
      if (this.type === 'value-string' || this.type === 'label-string') {
        if (Array.isArray(value)) {
          console.error(`${this.type}：数据格式错误`)
        }
        innerValue = (value as string || '').split(',').filter((res) => res)
      } else {
        innerValue = JSON.parse(JSON.stringify(value || []))
      }
      if (JSON.stringify(this.innerValue) !== JSON.stringify(innerValue)) {
        this.innerValue = innerValue
      }
    },
    setValue (newVal: string[] | number[], oldVal: string[] | number[]) {
      let innerNewValue!: string | number[] | string[]
      let innerOldValue!: string | number[] | string[]
      if (this.type === 'value-string' || this.type === 'label-string') {
        innerNewValue = newVal.join()
        innerOldValue = oldVal.join()
      } else {
        innerNewValue = JSON.parse(JSON.stringify(newVal))
        innerOldValue = JSON.parse(JSON.stringify(oldVal))
      }
      if (JSON.stringify(innerNewValue) !== JSON.stringify(innerOldValue)) {
        this.$emit('update:modelValue', innerNewValue)
      }
    }
  }
})
