import store from 'store2'

/**
 * 调用方式参考store2插件(例如:)config.get('menuEnum')
 * config: {menuEnum}
 */
export default class BaseCacheService {
  protected prefix = location.host + '/'
  public user = store.namespace(this.prefix + 'user')
  public config = store.namespace(this.prefix + 'config')
  public version = store.namespace(this.prefix + 'version')
  public clearAll () {
    this.user.clearAll()
    this.config.clearAll()
    this.version.clearAll()
  }
}
