import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormSelect = _resolveComponent("FormSelect")!
  const _component_FormCounter = _resolveComponent("FormCounter")!
  const _component_FormSwitch = _resolveComponent("FormSwitch")!
  const _component_FormGroupPopup = _resolveComponent("FormGroupPopup")!

  return (_openBlock(), _createBlock(_component_FormGroupPopup, {
    title: "赠送优惠券",
    load: _ctx.handleLoad,
    modelValue: _ctx.innerValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
    initForm: _ctx.rewardsForm,
    col: _ctx.col,
    fields: _ctx.rewardsFormFields
  }, {
    default: _withCtx(({ v }) => [
      _createVNode(_component_FormSelect, {
        modelValue: v.coupon_template_id,
        "onUpdate:modelValue": ($event: any) => ((v.coupon_template_id) = $event),
        field: _ctx.rewardsFormFields.coupon_template_id
      }, null, 8, ["modelValue", "onUpdate:modelValue", "field"]),
      _createVNode(_component_FormSelect, {
        modelValue: v.amount,
        "onUpdate:modelValue": ($event: any) => ((v.amount) = $event),
        field: _ctx.rewardsFormFields.amount
      }, null, 8, ["modelValue", "onUpdate:modelValue", "field"]),
      _createVNode(_component_FormCounter, {
        modelValue: v.give_number,
        "onUpdate:modelValue": ($event: any) => ((v.give_number) = $event),
        field: _ctx.rewardsFormFields.give_number
      }, null, 8, ["modelValue", "onUpdate:modelValue", "field"]),
      _createVNode(_component_FormCounter, {
        modelValue: v.expiry_day,
        "onUpdate:modelValue": ($event: any) => ((v.expiry_day) = $event),
        field: _ctx.rewardsFormFields.expiry_day
      }, null, 8, ["modelValue", "onUpdate:modelValue", "field"]),
      _createVNode(_component_FormSwitch, {
        modelValue: v.is_stack,
        "onUpdate:modelValue": ($event: any) => ((v.is_stack) = $event),
        field: _ctx.rewardsFormFields.is_stack
      }, null, 8, ["modelValue", "onUpdate:modelValue", "field"])
    ]),
    _: 1
  }, 8, ["load", "modelValue", "initForm", "col", "fields"]))
}