
import { defineComponent } from 'vue'
import ChartMixins from './ChartMixins'

export default defineComponent({
  mixins: [ChartMixins],
  data () {
    return {
    }
  },
  methods: {
    getOptions () {
      const series = this.series.map((res) => {
        res.type = 'bar'
        // res.barMinWidth = 50
        res.label = {
          show: true,
          formatter: ({ value }: { value: number }) => {
            if (value > 0) {
              return res._key.includes('_amount') ? value + '元' : value
            } else {
              return ''
            }
          }
        }
        return res
      })

      const valueIsAmountType = series.every((res) => res._key.includes('_amount'))

      return {
        title: {
          text: this.title
        },
        // 提示框
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          valueFormatter: (value: number) => {
            if (valueIsAmountType) {
              return value ? value + '元' : '-'
            } else {
              return value
            }
          }
        },
        legend: { textStyle: { fontSize: 16 }, top: '8%' },
        grid: { left: '3%', right: '5%', bottom: '5%', top: '20%', containLabel: true },
        xAxis: {
          type: 'category',
          data: this.category
        },
        yAxis: {
          type: 'value'
        },
        dataZoom: [
          {
            type: 'slider',
            showDetail: false,
            showDataShadow: false,
            minSpan: this._resolveSpan(series),
            maxSpan: this._resolveSpan(series),
            zoomLock: true,
            brushSelect: false
          }
        ],
        series
      }
    },
    _resolveSpan (series: any[]) {
      const stackIsTotal = series.some((res) => res.stack === 'total')
      if (stackIsTotal) {
        return this.category.length > 12 ? 50 : 100
      } else {
        return this.category.length > 12 ? 30 : 100
      }
    }
  }
})
