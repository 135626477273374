import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!

  return (_openBlock(), _createBlock(_component_el_table_column, {
    prop: _ctx.prop,
    label: _ctx.label,
    width: _ctx.width,
    "min-width": _ctx.minWidth,
    align: _ctx.tableService.align || _ctx.align,
    "class-name": _ctx.className
  }, {
    default: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", {
        value: _ctx.getValue(scope.row),
        row: scope.row
      }, () => [
        _createElementVNode("span", {
          style: _normalizeStyle({ color: _ctx.getColor(scope.row) })
        }, _toDisplayString(_ctx.getValue(scope.row)), 5)
      ])
    ]),
    _: 3
  }, 8, ["prop", "label", "width", "min-width", "align", "class-name"]))
}