import RouterService from '@/pk/src/service/RouterService'
import { Coin, Money, User, Notebook } from '@element-plus/icons-vue'
import { markRaw } from 'vue'

export default [
  {
    path: '/finance',
    component: () => import('pk/views/sider.vue'),
    meta: {
      title: '财务管理'
    },
    children: [
      {
        path: '/finance/salary-rule',
        component: () => import('@/biz/admin/views/finance/salary-rule/index.vue'),
        meta: {
          title: '薪资结构',
          icon: markRaw(Coin)
        },
        children: [
          {
            path: '/finance/salary-rule/form',
            component: () => import('@/biz/admin/views/finance/salary-rule/form.vue'),
            meta: {
              title: () => {
                return RouterService.query('id') ? '编辑薪资结构' : '添加薪资结构'
              },
              isForm: true
            }
          }
        ]
      },
      {
        path: '/finance/enterprise-fixed-overhead',
        component: () => import('@/biz/admin/views/finance/enterprise-fixed-overhead/index.vue'),
        meta: {
          title: '固定支出',
          icon: markRaw(Money)
        },
        children: [
          {
            path: '/finance/enterprise-fixed-overhead/form',
            component: () => import('@/biz/admin/views/finance/enterprise-fixed-overhead/form.vue'),
            meta: {
              title: () => {
                return RouterService.query('id') ? '编辑固定支出' : '添加固定支出'
              },
              isForm: true
            }
          }
        ]
      },
      {
        path: '/finance/enterprise-equity',
        component: () => import('@/biz/admin/views/finance/enterprise-equity/index.vue'),
        meta: {
          title: '股权结构',
          icon: markRaw(User)
        },
        children: [
          {
            path: '/finance/enterprise-equity/form',
            component: () => import('@/biz/admin/views/finance/enterprise-equity/form.vue'),
            meta: {
              title: () => {
                return RouterService.query('id') ? '编辑股权结构' : '添加股权结构'
              },
              isForm: true
            }
          }
        ]
      },
      {
        path: '/finance/enterprise-bill',
        component: () => import('@/biz/admin/views/finance/enterprise-bill/index.vue'),
        meta: {
          title: '公司账单',
          icon: markRaw(Notebook)
        }
      },
      {
        path: '/finance/salary-record',
        component: () => import('@/biz/admin/views/finance/salary-record/index.vue'),
        meta: {
          title: '薪资结算',
          icon: markRaw(Coin)
        },
        children: [
          {
            path: '/finance/salary-record/form',
            component: () => import('@/biz/admin/views/finance/salary-record/form.vue'),
            meta: {
              title: () => {
                return RouterService.query('id') ? '编辑薪资结算' : '添加薪资结算'
              },
              isForm: true
            }
          }
        ]
      },
      {
        path: '/finance/enterprise-equity-bonus',
        component: () => import('@/biz/admin/views/finance/enterprise-equity-bonus/index.vue'),
        meta: {
          title: '分红结算',
          icon: markRaw(Coin)
        },
        children: [
          {
            path: '/finance/enterprise-equity-bonus/form',
            component: () => import('@/biz/admin/views/finance/enterprise-equity-bonus/form.vue'),
            meta: {
              title: () => {
                return RouterService.query('id') ? '编辑分红结算' : '添加分红结算'
              },
              isForm: true
            }
          }
        ]
      }
    ]
  }
]
