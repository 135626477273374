import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createBlock(_component_el_image, {
    class: "CoverImage",
    style: _normalizeStyle(_ctx.styles),
    src: _ctx.innerSrc,
    fit: _ctx.fit,
    onLoad: _ctx.handleLoad
  }, null, 8, ["style", "src", "fit", "onLoad"]))
}