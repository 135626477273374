import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "DataRender" }
const _hoisted_2 = {
  key: 1,
  class: "DataRender-loading"
}
const _hoisted_3 = {
  key: 2,
  class: "result"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.status === _ctx.STATUS.SUCCESS)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : (_ctx.status === _ctx.STATUS.LOADING)
        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, null, 512)), [
            [_directive_loading, _ctx.status === _ctx.STATUS.LOADING]
          ])
        : (_ctx.status === _ctx.STATUS.ERROR)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.result.text), 1))
          : _createCommentVNode("", true)
  ]))
}