
import { defineComponent } from 'vue'
import UIService from 'pk/service/UIService'
import ImageMaskContainer from '../ImageMaskContainer.vue'
import ModalFileManageService from '../Modal/ModalFileManageService'
import FormMixins from './FormMixins'
import { Plus } from '@element-plus/icons-vue'
import { appEnv } from 'pk/service/ToolsService'

export default defineComponent({
  mixins: [FormMixins],
  components: {
    ImageMaskContainer,
    Plus
  },
  data () {
    return {
      ModalFileManageService: new ModalFileManageService(),
      innerValue: (this.modelValue || '') as string
    }
  },
  computed: {
    formItemElement (): any {
      return this.$refs.formItemElement
    }
  },
  methods: {
    handleChangeModal () {
      return this.ModalFileManageService.open({ debug: appEnv === 'dev', _model: this.formService.infoService?.modelName, info_id: this.formService.form.id })
        .then((data: { full_url: string }) => {
          this.innerValue = data.full_url
          this.formItemElement.clearValidate()
        })
        .catch((err) => UIService.toast.error(err.message))
    },
    handleDestroy (urls: string[]) {
      if (urls.includes(this.innerValue as string)) {
        this.innerValue = ''
      }
    }
  }
})
