
import { ISearchFields } from 'pk/interface/common'
import { defineComponent } from 'vue'
import SqlService, { ListItem } from 'pk/service/SqlService'
import RouterService from 'pk/service/RouterService'
import ValidateService from 'pk/service/ValidateService'
import getOperatorConfig from './config'
import QueryString from 'qs'
import bigIntValue from './bigIntValue.vue'
import dateTimeValue from './dateTimeValue.vue'
import dateValue from './dateValue.vue'
import intOptionsValue from './intOptionsValue.vue'
import intValue from './intValue.vue'
import stringOptionsValue from './stringOptionsValue.vue'
import stringValue from './stringValue.vue'
import intMultipleCascaderValue from './intMultipleCascaderValue.vue'
import { Edit, Delete } from '@element-plus/icons-vue'

interface SearchOptions {
  display_name: string;
  id: number | string;
  children?: SearchOptions[];
  [key: string]: any;
}

interface WhereItem { label: string; value: string }

export default defineComponent({
  name: 'SearchTool',
  components: {
    bigIntValue,
    dateTimeValue,
    dateValue,
    intOptionsValue,
    intValue,
    stringOptionsValue,
    stringValue,
    Edit,
    Delete,
    intMultipleCascaderValue
  },
  props: {
    fields: Object
  },
  data () {
    return {
      valueComponent: '',
      innerFields: [] as ISearchFields[],
      innerOptions: [] as SearchOptions[],
      innerOptionsProps: {
        label: 'display_name', value: 'value'
      },
      innerWheres: [] as WhereItem[],
      SqlService: new SqlService(),
      RouterService,
      form: {
        field: '',
        operator: '',
        value: '',
        valueType: ''
      } as ListItem,
      formFields: ValidateService.genRules({
        field: {
          prop: 'field',
          label: '字段',
          options: this.getFields(),
          rule: [ValidateService.required({ trigger: 'change' })],
          props: {
            value: 'name'
          }
        }
      }),
      FormElement: null as any,
      innerType: '' as undefined|string,
      innerUrl: '' as undefined|string
    }
  },
  mounted () {
    this.innerFields = this.getFields()
    this.initSearchList()
  },
  computed: {
    drElement (): any {
      return this.$refs.drElement
    }
  },
  methods: {
    createForm (field: string, row?: ListItem) {
      return Promise.resolve()
        .then(() => {
          const fieldItem = this.innerFields.find((res: ISearchFields) => res.name === field) as ISearchFields
          this.innerWheres = getOperatorConfig(fieldItem.valueType)
          this.innerOptions = JSON.parse(JSON.stringify(fieldItem.options || []))
          this.innerType = fieldItem.type
          this.innerUrl = fieldItem.url
          this.form.field = field
          this.form.operator = (row && row.operator) || this.innerWheres[0].value
          if (fieldItem.valueType === 'intOptions' && row && row.value) {
            this.form.value = row.value
          } else if (fieldItem.valueType === 'intMultipleCascader' && row && row.value) {
            this.form.value = Object.values(row.value as any).map((res) => Number(res))
          } else {
            this.form.value = (row && row.value as string) || ''
          }
          this.form.valueType = fieldItem.valueType
          this.valueComponent = this.form.valueType + 'Value'
          Object.assign(this.innerOptionsProps, fieldItem.props)
        })
    },
    addWhere () {
      const fieldItem = this.innerFields.find((res: ISearchFields) => res.name === this.form.field) as ISearchFields
      return this.FormElement.validate()
        .then(() => {
          const operatorItem = getOperatorConfig(fieldItem.valueType).find((res) => res.value === this.form.operator) as WhereItem
          let valueDisplayName = ''
          if (fieldItem.options && (fieldItem.options as SearchOptions[]).length > 0) {
            if (fieldItem.valueType === 'intOptions') {
              this.form.value = (this.form.value as string[]).map((res) => Number(res))
              valueDisplayName = (fieldItem.options as SearchOptions[])
                .filter((res) => (this.form.value as (string|number)[]).includes(res[this.innerOptionsProps.value] as string))
                .map((res) => res[this.innerOptionsProps.label])
                .join()
            }
          }
          if (fieldItem.valueType === 'intMultipleCascader') {
            this.form.value = Object.values(this.form.value as any).map((res) => Number(res))
            if (this.form.value && this.form.value.length > 5) {
              valueDisplayName = `${this.form.value.length}个${fieldItem.display_name}`
            }
          }
          this.SqlService.where({
            field: this.form.field,
            operator: this.form.operator,
            value: this.form.value,
            fieldDisplayName: fieldItem.display_name,
            operatorDisplayName: operatorItem.label,
            valueDisplayName,
            valueType: fieldItem.valueType
          })
        })
        .then(() => this.initForm(fieldItem))
        .then(() => this.resetFields())
    },
    initForm (fieldItem: ISearchFields) {
      this.form.field = ''
      this.form.operator = ''
      this.form.value = ''
      this.form.valueType = ''
      this.valueComponent = ''
      this.innerWheres = []
      this.innerOptions = []
      this.innerOptionsProps = Object.assign({ label: 'display_name', value: 'value' }, fieldItem.props || {})
      this.drElement.reload()
    },
    resetFields () {
      return Promise.resolve()
        .then(() => {
          const fields: string[] = this.SqlService.getFields()
          this.innerFields = this.getFields().filter((res: ISearchFields) => !fields.includes(res.name))
        })
    },
    handleSubmit () {
      const _search = this.SqlService.get()
      RouterService.replace(RouterService.getPath(), { ...this.$route.query, page: 1, _search: _search.length > 0 ? QueryString.stringify(_search) : undefined })
      this.$emit('reload', { ...this.$route.query, page: 1, _search: _search.length > 0 ? _search : undefined })
    },
    getFields () {
      return this.fields ? JSON.parse(JSON.stringify(this.fields)) : []
    },
    handleEditTag (index: number) {
      const item = this.SqlService.getItem(index) as ListItem
      const fieldItem = this.getFields().find((res: ISearchFields) => res.name === item.field) as ISearchFields
      Object.assign(this.form, {
        field: item.field,
        operator: item.operator,
        value: item.value,
        valueType: fieldItem.valueType
      })
      this.handleRemoveTag(index)
      this.innerWheres = getOperatorConfig(fieldItem.valueType)
      this.innerOptions = JSON.parse(JSON.stringify(fieldItem.options || []))
      this.valueComponent = fieldItem.valueType + 'Value'
    },
    handleRemoveTag (index: number) {
      this.SqlService.removeItem(index)
      this.resetFields()
    },
    handleChangeWhere () {
      if (this.form.valueType === 'date' || this.form.valueType === 'datetime') {
        this.form.value = ''
      }
    },
    initSearchList () {
      const searchList = Object.values(QueryString.parse(RouterService.query('_search') as string)) as unknown as ListItem[]
      searchList.reduce((acc, row) => {
        return acc.then(() => this.createForm(row.field, row)).then(() => this.addWhere())
      }, Promise.resolve())
    },
    handleLoadSuccess () {
      this.FormElement = this.$refs.FormElement
    }
  }
})
