
import { defineComponent } from 'vue'
import ModalContainer from './ModalContainer.vue'

export default defineComponent({
  components: {
    ModalContainer
  },
  props: {
    modalService: Object,
    index: {
      type: Number,
      default: 0
    },
    images: Array,
    autoplay: Boolean
  },
  data () {
    return {
      height: ''
    }
  },
  methods: {
    handleImgLoad (index: number) {
      if (index === this.index) {
        this.handleChange(this.index)
      }
    },
    handleChange (index: number) {
      this.height = ((this.$refs.imgs as any)[index] as any).$el.clientHeight + 'px'
    }
  }
})
