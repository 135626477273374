import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!

  return (_openBlock(), _createBlock(_component_el_table_column, {
    prop: _ctx.prop,
    label: _ctx.label,
    width: _ctx.width,
    "min-width": _ctx.minWidth || 91,
    align: _ctx.tableService.align || _ctx.align,
    "class-name": _ctx.className
  }, {
    default: _withCtx((scope) => [
      (scope.row[_ctx.prop])
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: scope.row[_ctx.prop],
            width: "70",
            height: "70"
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["prop", "label", "width", "min-width", "align", "class-name"]))
}