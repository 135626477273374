import BaseCacheService from 'pk/service/BaseCacheService'
import store from 'store2'

class CacheService extends BaseCacheService {
  public HrJob = store.namespace(this.prefix + 'HrJob')
  public HrResume = store.namespace(this.prefix + 'HrResume')
  public SdSupply = store.namespace(this.prefix + 'SdSupply')
  public SdDemand = store.namespace(this.prefix + 'SdDemand')

  public clearAll () {
    super.clearAll()
    this.HrJob.clearAll()
    this.HrResume.clearAll()
    this.SdSupply.clearAll()
    this.SdDemand.clearAll()
  }
}

export default new CacheService()
