
import { defineComponent } from 'vue'
import FormMixins from './FormMixins'
import UIService from 'pk/service/UIService'
import ImageMaskContainer from '../ImageMaskContainer.vue'
import ModalFileManageService from '../Modal/ModalFileManageService'
import { Plus } from '@element-plus/icons-vue'
import { appEnv } from 'pk/service/ToolsService'

export default defineComponent({
  mixins: [FormMixins],
  components: {
    Plus,
    ImageMaskContainer
  },
  data () {
    return {
      ModalFileManageService: new ModalFileManageService(),
      innerValue: (this.modelValue || []) as string[]
    }
  },
  computed: {
    formItemElement (): any {
      return this.$refs.formItemElement
    }
  },
  methods: {
    handleChangeModal () {
      return this.ModalFileManageService.open({
        debug: appEnv === 'dev',
        _model: this.formService.infoService?.modelName,
        info_id: this.formService.form.id,
        type: 'multiple',
        destroy: this.handleDestroy
      })
        .then((data: { full_url: string }[]) => {
          const imgs = data.map((res) => res.full_url)
          this.innerValue = (this.innerValue as string[]).concat(imgs)
        })
        .catch((err) => UIService.toast.error(err.message))
    },
    handleRemove (index: number) {
      (this.innerValue as string[]).splice(index, 1)
    },
    handleDestroy (urls: string[]) {
      this.innerValue = (this.innerValue as string[]).filter((url: string) => !urls.includes(url))
    }
  }
})
