import RouterService from '@/pk/src/service/RouterService'
import { Medal, Notebook, User } from '@element-plus/icons-vue'
import { markRaw } from 'vue'

export default [
  {
    path: '/admin',
    component: () => import('pk/views/sider.vue'),
    meta: {
      title: '后台管理'
    },
    children: [
      {
        path: '/admin/user',
        component: () => import('@/biz/admin/views/admin/user/index.vue'),
        meta: {
          title: '后台成员',
          icon: markRaw(User)
        },
        children: [
          {
            path: '/admin/user/form',
            component: () => import('@/biz/admin/views/admin/user/form.vue'),
            meta: {
              title: () => {
                return RouterService.query('id') ? '编辑后台成员' : '添加后台成员'
              },
              isForm: true
            }
          }
        ]
      },
      {
        path: '/admin/role',
        component: () => import('@/biz/admin/views/admin/role/index.vue'),
        meta: {
          title: '后台角色',
          icon: markRaw(Medal)
        },
        children: [
          {
            path: '/admin/role/form',
            component: () => import('@/biz/admin/views/admin/role/form.vue'),
            meta: {
              title: () => {
                return RouterService.query('id') ? '编辑后台角色' : '添加后台角色'
              },
              isForm: true
            }
          },
          {
            path: '/admin/role/permissions',
            component: () => import('@/biz/admin/views/admin/role/permissions.vue'),
            meta: {
              title: '权限管理',
              isForm: true
            }
          }
        ]
      },
      {
        path: '/admin/log',
        component: () => import('pk/views/admin/log/index.vue'),
        meta: {
          title: '操作日志',
          icon: markRaw(Notebook)
        }
      }
    ]
  }
]
