import IOService from 'pk/service/IOService'
import BaseAbstract from 'pk/abstract/BaseAbstract'

class SdSupplyService extends BaseAbstract {
  modelName = 'SdSupply'
  displayName = '供应'
  basePath = '/sd/supply'

  index (params: any) {
    return IOService.get('sd_supply', params)
  }

  store (params: { is_push: number; industry: number[]; city: number }) {
    return IOService.post('sd_supply', params)
  }

  show (id: number) {
    return IOService.get(`sd_supply/${id}`)
  }

  update (params: { id: string; is_push: number; industry: number[]; city: number }) {
    return IOService.put(`sd_supply/${params.id}`, params)
  }

  destroy (id: string) {
    return IOService.delete(`sd_supply/${id}`)
  }
}

export default new SdSupplyService()
