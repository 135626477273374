
import { defineComponent } from 'vue'
import ModalAbstract from './ModalAbstract'

export default defineComponent({
  props: {
    modalService: {
      type: Object as () => ModalAbstract,
      required: true
    },
    params: {
      type: Object,
      default: () => {
        return {}
      }
    },
    customClass: String
  },
  data () {
    return {
      innerParams: {
        top: '10vh',
        width: '800px',
        hideCloseBtn: false
      }
    }
  },
  created () {
    Object.assign(this.innerParams, this.params)
  },
  methods: {
    handleClose () {
      return this.modalService.cancel()
    }
  }
})
