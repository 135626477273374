import { defineComponent, shallowRef } from 'vue'
import echarts from './echarts'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    series: {
      type: Array as () => { [x: string]: any }[],
      required: true
    },
    category: {
      type: Array as () => string[],
      required: true
    }
  },
  mounted () {
    const el = this.$el.querySelector('.chart-report')
    this.chart = echarts.init(el)
    this.chart.setOption(this.getOptions())
  },
  data () {
    return {
      chart: shallowRef(null) as any
    }
  },
  methods: {
    getOptions () {
      //
    }
  }
})
