import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ModalContainer" }
const _hoisted_2 = { class: "ModalContainer-content" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.modalService.isShow,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalService.isShow) = $event)),
      "close-on-click-modal": false,
      onClose: _ctx.handleClose,
      class: _normalizeClass('ModalContainer-modal ' + _ctx.customClass),
      "append-to-body": true,
      top: _ctx.innerParams.top,
      width: _ctx.innerParams.width
    }, {
      footer: _withCtx(() => [
        _renderSlot(_ctx.$slots, "footer", {}, () => [
          (_ctx.innerParams.hideCloseBtn === false)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _createVNode(_component_el_button, {
                  type: "primary",
                  onClick: _ctx.handleClose
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("关 闭")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]),
      _: 3
    }, 8, ["modelValue", "onClose", "class", "top", "width"])
  ]))
}