import CacheService from '@/service/CacheService'
import areaOptions from 'pk/assets/json/area'

export interface OptionBoolItem {
  display_name: string;
  value: number;
  [key: string]: any;
}

export interface OptionItem {
  id?: string;
  config_id?: number;
  display_name: string;
  value?: number | string;
  disabled?: boolean;
  [key: string]: any;
}

export interface ConfigItem {
  id: string;
  name: string;
  display_name: string;
  guard_name: string;
  config_options: OptionItem[];
}

class ConstService {
  getValue (name: string) {
    const configs = CacheService.config.get('config') || []
    const item = configs.find((res: { name: string }) => res.name === name)
    return item ? item.value : ''
  }

  getOptions (className: string, field: string) {
    const configs: ConfigItem[] = CacheService.config.get('config_options') || []
    let config = configs.find((res) => res.name === className + ':' + field)
    if (!config) {
      config = configs.find((res) => res.name === '_global:' + field)
    }
    return config ? config.config_options : []
  }

  getColor (name: string) {
    switch (name) {
      case 'success':
        return '#67C23A'
      case 'warning':
        return '#E6A23C'
      case 'error':
        return '#F56C6C'
      case 'click':
        return '#409EFF'
      default:
        if (name.includes('#')) {
          return name
        } else {
          return '#303133'
        }
    }
  }

  getAreaOptions () {
    return JSON.parse(JSON.stringify(areaOptions))
  }

  getBoolOptions () {
    return [
      {
        value: 0,
        display_name: '否'
      },
      {
        value: 1,
        display_name: '是'
      }
    ]
  }
}

export default new ConstService()
