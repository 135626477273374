
import { defineComponent } from 'vue'
import echarts from './echarts'
import ChartMixins from './ChartMixins'

export default defineComponent({
  mixins: [ChartMixins],
  data () {
    return {
    }
  },
  methods: {
    getOptions () {
      return {
        title: {
          text: this.title
        },
        // 提示框
        tooltip: {
          show: true,
          trigger: 'item'
        },
        grid: {
          top: 80,
          right: 30,
          bottom: 40,
          left: 0,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.category
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: this.title,
          type: 'line',
          smooth: true, // 是否平滑
          symbol: 'circle',
          symbolSize: 8,
          lineStyle: {
            color: '#48B3FF',
            width: 2
          },
          markPoint: {
            data: [
              { type: 'min', name: '最小值' },
              { type: 'max', name: '最大值' }
            ]
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(195,230,255,1)'
            },
            {
              offset: 1,
              color: 'rgba(195,230,255,0.1)'
            }], false),
            shadowColor: 'rgba(195,230,255,0.1)',
            shadowBlur: 20
          },
          data: []
        }]
      }
    }
  }
})
