
import { defineComponent } from 'vue'
import ValidateService from 'pk/service/ValidateService'
import IOService from 'pk/service/IOService'

interface RewardItem {
  reward_name: string;
  coupon_template_id: string;
  amount: number;
  give_number: number;
  expiry_day: number;
}

export default defineComponent({
  props: {
    modelValue: {
      type: Array as () => RewardItem[],
      required: true
    },
    col: {
      type: Number,
      default: 3
    }
  },
  data () {
    return {
      innerValue: this.modelValue,
      rewardsForm: {
        reward_name: 'coupon',
        coupon_template_id: '',
        amount: '',
        give_number: 1,
        expiry_day: 30,
        is_stack: 0
      },
      rewardsFormFields: ValidateService.genRules({
        coupon_template_id: {
          prop: 'coupon_template_id',
          label: '优惠券模板',
          options: [],
          props: {
            value: 'id'
          },
          rule: [ValidateService.required({ trigger: 'change' })]
        },
        amount: {
          prop: 'amount',
          label: '金额',
          options: [
            { display_name: '3元', value: 3 },
            { display_name: '5元', value: 5 },
            { display_name: '10元', value: 10 },
            { display_name: '20元', value: 20 }
          ],
          rule: [ValidateService.required({ trigger: 'change' })]
        },
        give_number: {
          prop: 'give_number',
          label: '奖励数量',
          min: 1,
          rule: [ValidateService.required({ trigger: 'change' }), ValidateService.min(1)]
        },
        expiry_day: {
          prop: 'expiry_day',
          label: '到期天数',
          min: 1,
          rule: [ValidateService.required({ trigger: 'change' }), ValidateService.min(1)]
        },
        is_stack: {
          prop: 'is_stack',
          label: '是否可叠加'
        }
      })
    }
  },
  methods: {
    handleLoad () {
      return IOService.get('coupon_template')
        .then((res) => {
          this.rewardsFormFields.coupon_template_id.options = res.data
        })
    }
  }
})
