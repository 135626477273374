import CacheService from '@/service/CacheService'
import IOService from './IOService'
import VersionService from './VersionService'

interface LoginParams {
  username: string;
  password: string;
}

interface UserInfo {
  id: string;
  username: string;
  nickname: string;
  phone: string;
  current_enterprise_id: number;
}

class AuthService {
  info: UserInfo = {
    id: '',
    nickname: '',
    username: '',
    phone: '',
    current_enterprise_id: 0
  }

  constructor () {
    Object.assign(this.info, CacheService.user.getAll())
  }

  login (params: LoginParams) {
    return IOService.post('auth/login', params)
      .then((res) => {
        CacheService.user.setAll(res.data)
        this.updateData(res.data)
      })
      .then(() => this.getUserConfig())
  }

  logout () {
    return Promise.resolve()
      .then(() => {
        CacheService.clearAll()
        VersionService.clearAll()
        return '注销成功'
      })
  }

  update () {
    return this.getUserConfig()
  }

  private updateData (params: UserInfo) {
    Object.assign(this.info, params)
  }

  private getUserConfig () {
    return IOService.get('auth/getUserConfig')
      .then((res) => {
        CacheService.user.setAll(res.data.user)
        this.updateData(res.data.user)
        const interface_permissions = res.data.user.admin_roles.map((item: any) => item.interface_permissions).flat()
        CacheService.user.set('interface_permissions', interface_permissions || [])
        const menu_permissions = res.data.user.admin_roles.map((item: any) => item.menu_permissions).flat()
        CacheService.user.set('menu_permissions', menu_permissions || [])
      })
  }

  hasPermission (name: string) {
    const permissions: string[] = CacheService.user.get('interface_permissions') || []
    return this.hasRoot() || permissions.includes(name)
  }

  hasMenu (name: string) {
    const menus: string[] = CacheService.user.get('menu_permissions') || []
    return this.hasRoot() || menus.includes(name)
  }

  hasRoot () {
    const roles = CacheService.user.get('admin_roles') || []
    return roles.some((res: any) => res.name === 'root')
  }
}

export default new AuthService()
