import ValidateService from '../pk/src/service/ValidateService'

ValidateService.isInputPhone = () => {
  return {
    isInputPhone: {
      validator: (rule: any, value: string, callback: Function) => {
        const res = value.replace(/\s/g, '').match(/1\d{10}/g)
        if (res) {
          callback(new Error('含有手机号无法提交'))
        }
        callback()
      },
      trigger: 'blur'
    }
  }
}
