
import { IFormFieldItem } from 'pk/interface/common'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: [String, Number],
    field: {
      type: Object as () => IFormFieldItem,
      required: true
    },
    labelWidth: String
  }
})
