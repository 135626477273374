import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"text-align":"right","margin":"0"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    placement: "top",
    trigger: "click",
    width: "160",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event))
  }, {
    reference: _withCtx(() => [
      _createVNode(_component_el_button, _mergeProps({ type: _ctx.type }, _ctx.$attrs), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createTextVNode("删除")
          ])
        ]),
        _: 3
      }, 16, ["type"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_button, {
          size: "small",
          link: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode("取消")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          size: "small",
          type: "primary",
          onClick: _ctx.handleClick,
          loading: _ctx.loading
        }, {
          default: _withCtx(() => [
            _createTextVNode("确定")
          ]),
          _: 1
        }, 8, ["onClick", "loading"])
      ])
    ]),
    _: 3
  }, 8, ["visible"]))
}