
import { OptionItem } from 'pk/service/ConstService'
import { defineComponent } from 'vue'
import FormMixins from './FormMixins'

export default defineComponent({
  mixins: [FormMixins],
  data () {
    return {
      props: {
        value: 'value',
        label: 'display_name'
      },
      options: [] as OptionItem[]
    }
  },
  created () {
    Object.assign(this.props, this.field.props)
    if (this.field.options) {
      this.options = this.field.options
    } else if (this.formService.infoService) {
      this.options = this.formService.infoService.getOptions(this.field.prop)
    }
  }
})
