import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    class: "FormInput",
    label: _ctx.field.label,
    prop: _ctx.field.prop,
    "label-width": _ctx.labelWidth
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_input, {
        style: _normalizeStyle({ width: _ctx.field.width + 'px' }),
        value: _ctx.modelValue,
        disabled: ""
      }, null, 8, ["style", "value"])
    ]),
    _: 1
  }, 8, ["label", "prop", "label-width"]))
}