import { IPagination } from 'pk/interface/common'
import { PromiseResult } from 'pk/service/IOService'
import RouterService from 'pk/service/RouterService'
import QueryString from 'qs'

export default class TableFactory {
  public list = [] as any[]
  public isLoading = false
  public infoService = null as any
  private onLoad = null as any
  private onBeforeLoad = null as any
  public isShowPagination = false
  public emptyText = '暂无数据'
  public align = ''
  public searchParams = { _search: Object.values(QueryString.parse(RouterService.query('_search') as string)) }
  private isModal = false
  public pagination: IPagination = {
    page: 1,
    limit: Number(RouterService.query('limit')) || 10,
    total: 0
  }

  constructor(
    { infoService = null, onLoad = null, onBeforeLoad = null, align = '', isModal = false } = {} as {
      infoService?: any
      onLoad?: any
      onBeforeLoad?: any
      emptyText?: string
      align?: string
      isModal?: boolean
    }
  ) {
    this.infoService = infoService
    this.onLoad = onLoad
    this.onBeforeLoad = onBeforeLoad
    this.align = align
    this.isModal = isModal
    this.pagination.page = this.isModal ? 1 : Number(RouterService.query('page')) || 1
  }

  public load(params = {}) {
    return Promise.resolve().then(() => {
      if (!this.isLoading) {
        this.isLoading = true
        return Promise.resolve()
          .then(() => {
            if (this.onBeforeLoad) {
              return this.onBeforeLoad()
            }
          })
          .then(() => {
            if (this.onLoad) {
              return this.onLoad({
                ...this.pagination,
                ...this.searchParams,
                ...params
              })
            } else if (this.infoService) {
              return this.infoService.index({
                ...this.pagination,
                ...this.searchParams,
                ...params
              })
            }
          })
          .then((res: PromiseResult) => {
            if (Array.isArray(res)) {
              this.list = res
              this.pagination.total = this.list.length
            } else if (res && Array.isArray(res.data)) {
              this.list = res.data
              this.pagination.total = this.list.length
            } else if (res && res.data && res.data.data) {
              this.isShowPagination = true
              this.pagination.total = res.data.total
              this.list = res.data.data
            }
            this.isLoading = false
            return res
          })
          .catch((err: any) => {
            this.isLoading = false
            if (err && err.message) {
              this.emptyText = err.message
            }
            return Promise.reject(err)
          })
      }
    })
  }

  public pageLoad() {
    if (this.isModal) {
      this.load()
    } else {
      RouterService.replace(RouterService.getPath(), {
        ...RouterService.getRouteQuery(),
        page: this.pagination.page,
        limit: this.pagination.limit
      })
    }
  }

  public reload(params = {}) {
    this.list = []
    return this.load(params)
  }

  public getList() {
    return this.list
  }

  public refresh() {
    return this.reload()
  }
}
