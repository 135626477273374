
import { IPagination } from 'pk/interface/common'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    pagination: {
      type: Object as () => IPagination,
      required: true
    }
  },
  methods: {
    changePage (page: number) {
      this.pagination.page = page
      this.$emit('changePage')
    },
    changePageSize (pageSize: number) {
      this.pagination.limit = pageSize
      this.changePage(1)
    }
  }
})
