
import { defineComponent } from 'vue'
import FormMixins from './FormMixins'
import UIService from 'pk/service/UIService'
import ImageMaskContainer from '../ImageMaskContainer.vue'
import ModalFileManageService from '../Modal/ModalFileManageService'
import { Plus, Close } from '@element-plus/icons-vue'
import { appEnv } from 'pk/service/ToolsService'

interface ValueItem {
  name: string
  url: string
}

export default defineComponent({
  mixins: [FormMixins],
  components: {
    Plus,
    Close,
    ImageMaskContainer
  },
  data() {
    return {
      ModalFileManageService: new ModalFileManageService(),
      innerValue: (this.modelValue || []) as ValueItem[]
    }
  },
  computed: {
    formItemElement(): any {
      return this.$refs.formItemElement
    }
  },
  methods: {
    handleChangeModal() {
      return this.ModalFileManageService.open({
        debug: appEnv === 'dev',
        _model: this.formService.infoService?.modelName,
        info_id: this.formService.form.id,
        type: 'multiple',
        destroy: this.handleDestroy,
        mime: 'file'
      })
        .then((data: { full_url: string; name: string }[]) => {
          const imgs = data.map((res) => {
            return {
              name: res.name,
              url: res.full_url
            }
          })
          this.innerValue = this.innerValue.concat(imgs)
        })
        .catch((err) => UIService.toast.error(err.message))
    },
    handleRemove(index: number) {
      this.innerValue.splice(index, 1)
    },
    handleDestroy(urls: string[]) {
      this.innerValue = this.innerValue.filter((res: ValueItem) => !urls.includes(res.url))
    }
  }
})
