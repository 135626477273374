import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonSubmit = _resolveComponent("ButtonSubmit")!
  const _component_el_table_column = _resolveComponent("el-table-column")!

  return (_openBlock(), _createBlock(_component_el_table_column, {
    prop: _ctx.prop,
    label: _ctx.label,
    width: _ctx.width,
    "min-width": _ctx.minWidth || 108,
    align: _ctx.tableService.align || _ctx.align,
    "class-name": _ctx.className
  }, {
    default: _withCtx((scope) => [
      _createVNode(_component_ButtonSubmit, {
        type: "default",
        disabled: _ctx.getValue(scope.row).length === 0,
        size: "small",
        click: () => _ctx.handleOpenImages(scope.row)
      }, {
        default: _withCtx(() => [
          _createTextVNode("总共" + _toDisplayString(_ctx.getValue(scope.row).length) + "张图", 1)
        ]),
        _: 2
      }, 1032, ["disabled", "click"])
    ]),
    _: 1
  }, 8, ["prop", "label", "width", "min-width", "align", "class-name"]))
}