
import ValidateService from 'pk/service/ValidateService'
import { defineComponent } from 'vue'
import SearchMixins from './SearchMixins'

export default defineComponent({
  mixins: [SearchMixins],
  data () {
    return {
      field: ValidateService.genRule({
        prop: 'value',
        label: '值',
        rule: [ValidateService.required]
      })
    }
  }
})
