import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"color":"#666"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    label: _ctx.field.label,
    prop: _ctx.field.prop,
    rules: _ctx.field.rule
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_input_number, {
        modelValue: _ctx.innerValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event)),
        min: _ctx.min,
        max: _ctx.max,
        precision: _ctx.field.precision,
        label: '请输入' + _ctx.field.label,
        disabled: _ctx.field.disabled
      }, null, 8, ["modelValue", "min", "max", "precision", "label", "disabled"]),
      (_ctx.field.tips)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.field.tips), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["label", "prop", "rules"]))
}