import HrJobService from '@/biz/admin/services/Hr/HrJobService'
import HrResumeService from '@/biz/admin/services/Hr/HrResumeService'
import { FirstAidKit, FolderAdd, FolderChecked, FolderOpened, Tickets } from '@element-plus/icons-vue'
import { markRaw } from 'vue'

export default [
  {
    path: '/hr',
    component: () => import('pk/views/sider.vue'),
    meta: {
      title: '求职招聘'
    },
    children: [
      {
        path: '/hr/job',
        component: () => import('pk/views/view.vue'),
        meta: {
          title: '招聘管理',
          isGroup: true
        },
        children: [
          {
            path: '/hr/job/info-check',
            component: () => import('@/biz/admin/views/info/info-check/index.vue'),
            meta: {
              title: '信息审核',
              icon: markRaw(FolderChecked),
              query: {
                _model: HrJobService.modelName
              }
            },
            children: [
              {
                path: '/hr/job/info-check/form',
                component: () => import('@/biz/admin/views/info/info-check/form.vue'),
                meta: {
                  title: '审核详情',
                  isForm: true
                }
              }
            ]
          },
          {
            path: '/hr/job/list',
            component: () => import('@/biz/admin/views/hr/job/index.vue'),
            meta: {
              title: '信息列表',
              icon: markRaw(Tickets)
            },
            children: [
              {
                path: '/hr/job/list/form',
                component: () => import('@/biz/admin/views/hr/job/form.vue'),
                meta: {
                  title: '信息详情',
                  isForm: true
                }
              }
            ]
          },
          {
            path: '/hr/job/info-provide',
            component: () => import('@/biz/admin/views/info/info-provide/index.vue'),
            meta: {
              title: '信息提供',
              icon: markRaw(FolderAdd),
              query: {
                _model: HrJobService.modelName
              }
            },
            children: [
              {
                path: '/hr/job/info-provide/form',
                component: () => import('@/biz/admin/views/info/info-provide/form.vue'),
                meta: {
                  title: '提供详情',
                  isForm: true
                }
              }
            ]
          },
          {
            path: '/hr/job/info-complaint',
            component: () => import('@/biz/admin/views/info/info-complaint/index.vue'),
            meta: {
              title: '信息投诉',
              icon: markRaw(FirstAidKit),
              query: {
                _model: HrJobService.modelName
              }
            },
            children: [
              {
                path: '/hr/job/info-complaint/form',
                component: () => import('@/biz/admin/views/info/info-complaint/form.vue'),
                meta: {
                  title: '投诉详情',
                  isForm: true
                }
              }
            ]
          },
          {
            path: '/hr/job/info-delivery',
            component: () => import('@/biz/admin/views/info/info-delivery/index.vue'),
            meta: {
              title: '信息投递',
              icon: markRaw(FolderOpened),
              query: {
                _model: HrJobService.modelName
              }
            }
          }
        ]
      },
      {
        path: '/hr/resume',
        component: () => import('pk/views/view.vue'),
        meta: {
          title: '求职管理',
          isGroup: true
        },
        children: [
          {
            path: '/hr/resume/info-check',
            component: () => import('@/biz/admin/views/info/info-check/index.vue'),
            meta: {
              title: '信息审核',
              icon: markRaw(FolderChecked),
              query: {
                _model: HrResumeService.modelName
              }
            },
            children: [
              {
                path: '/hr/resume/info-check/form',
                component: () => import('@/biz/admin/views/info/info-check/form.vue'),
                meta: {
                  title: '审核详情',
                  isForm: true
                }
              }
            ]
          },
          {
            path: '/hr/resume/list',
            component: () => import('@/biz/admin/views/hr/resume/index.vue'),
            meta: {
              title: '信息列表',
              icon: markRaw(Tickets)
            },
            children: [
              {
                path: '/hr/resume/list/form',
                component: () => import('@/biz/admin/views/hr/resume/form.vue'),
                meta: {
                  title: '信息详情',
                  isForm: true
                }
              }
            ]
          },
          {
            path: '/hr/resume/info-provide',
            component: () => import('@/biz/admin/views/info/info-provide/index.vue'),
            meta: {
              title: '信息提供',
              icon: markRaw(FolderAdd),
              query: {
                _model: HrResumeService.modelName
              }
            },
            children: [
              {
                path: '/hr/job/info-provide/form',
                component: () => import('@/biz/admin/views/info/info-provide/form.vue'),
                meta: {
                  title: '提供详情',
                  isForm: true
                }
              }
            ]
          },
          {
            path: '/hr/resume/info-complaint',
            component: () => import('@/biz/admin/views/info/info-complaint/index.vue'),
            meta: {
              title: '信息投诉',
              icon: markRaw(FirstAidKit),
              query: {
                _model: HrResumeService.modelName
              }
            },
            children: [
              {
                path: '/hr/resume/info-complaint/form',
                component: () => import('@/biz/admin/views/info/info-complaint/form.vue'),
                meta: {
                  title: '投诉详情',
                  isForm: true
                }
              }
            ]
          }
        ]
      }
    ]
  }
]
