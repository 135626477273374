
import { ITableColumnAction } from 'pk/interface/common'
import AuthService from 'pk/service/AuthService'
import { defineComponent } from 'vue'
import BaseAbstract from 'pk/abstract/BaseAbstract'
import RouterService from 'pk/service/RouterService'
import ModalService from 'pk/components/Modal/ModalService'

interface InfoService {
  destroy: Function;
}

export default defineComponent({
  props: {
    buttons: {
      type: Array as () => ITableColumnAction[],
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    infoService: {
      type: Object as () => BaseAbstract & InfoService
    }
  },
  data () {
    return {
      innerButtons: [] as ITableColumnAction[]
    }
  },
  created () {
    this.innerButtons = this.buttons
      .map((res) => {
        if (res.children) {
          res.children = res.children.filter((row) => {
            return this.hasPermission(row)
          })
        }
        return res
      })
      .filter((res) => {
        if (res.name === '更多' && res.children) {
          return res.children.length > 0
        } else {
          return this.hasPermission(res)
        }
      })
  },
  methods: {
    handleDestroy (v: { onClick: Function }) {
      return Promise.resolve()
        .then(() => {
          if (v.onClick) {
            return v.onClick(this.row)
          } else {
            return this.infoService && this.infoService.destroy(this.row.id)
          }
        })
        .then((res) => {
          this.$emit('refresh')
          return res
        })
    },
    handleEdit (v: { onClick: Function }) {
      return Promise.resolve()
        .then(() => {
          if (v.onClick) {
            return v.onClick(this.row)
          } else {
            return RouterService.pushForm({ id: this.row.id })
          }
        })
    },
    handleShow (v: { onClick: Function }) {
      return Promise.resolve()
        .then(() => {
          if (v.onClick) {
            return v.onClick(this.row)
          } else {
            return (new ModalService(() => import(`@/biz/${process.env.VUE_APP_DIRECTORY}/views${RouterService.getPath()}/show.vue`))).open({ id: this.row.id })
          }
        })
    },
    handleOnShow (v: ITableColumnAction) {
      if (v.onShow) {
        return v.onShow(this.row)
      } else {
        return true
      }
    },
    hasPermission (res: ITableColumnAction) {
      if (res.name === '编辑') {
        res.permission = 'update'
      } else if (res.name === '删除') {
        res.permission = 'destroy'
      } else if (res.name === '查看详情') {
        res.permission = 'show'
      }
      if (res.permission && this.infoService) {
        return AuthService.hasPermission(this.infoService.getPermissionName(res.permission))
      } else {
        return true
      }
    },
    fetchType (type: any) {
      if (typeof type === 'function') {
        return type(this.row)
      } else {
        return type || 'warning'
      }
    }
  }
})
