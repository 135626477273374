import IOService from 'pk/service/IOService'
import BaseAbstract from 'pk/abstract/BaseAbstract'

class HrJobService extends BaseAbstract {
  modelName = 'HrJob'
  displayName = '招聘'
  basePath = '/hr/job'

  index (params: any) {
    return IOService.get('hr_job', params)
  }

  store (params: { is_push: number; industry: number[]; city: number }) {
    return IOService.post('hr_job', params)
  }

  show (id: number) {
    return IOService.get(`hr_job/${id}`)
  }

  update (params: { id: string; is_push: number; industry: number[]; city: number }) {
    return IOService.put(`hr_job/${params.id}`, params)
  }

  destroy (id: string) {
    return IOService.delete(`hr_job/${id}`)
  }
}

export default new HrJobService()
