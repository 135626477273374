
import { Close, View } from '@element-plus/icons-vue'
import { defineComponent } from 'vue'
import ModalCarouselService from './Modal/ModalCarouselService'

export default defineComponent({
  components: {
    Close,
    View
  },
  props: {
    modelValue: {
      type: [String, Array as () => string[]],
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    size: {
      type: [String, Number],
      default: 150
    },
    disabled: Boolean
  },
  watch: {
    modelValue (val) {
      this.innerValue = val
    },
    innerValue (val) {
      this.$emit('update:modelValue', val)
    }
  },
  data () {
    return {
      innerValue: this.modelValue,
      ModalCarouselService: new ModalCarouselService()
    }
  },
  methods: {
    handleRemove () {
      if (Array.isArray(this.innerValue)) {
        this.innerValue.splice(this.index, 1)
      } else {
        this.innerValue = ''
      }
    },
    handleViewImages () {
      this.ModalCarouselService.open({
        images: Array.isArray(this.innerValue) ? this.innerValue : [this.innerValue],
        index: this.index,
        autoplay: false
      })
    }
  }
})
