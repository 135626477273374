import ConstService from 'pk/service/ConstService'

export default abstract class BaseAbstract {
  modelName!: string
  displayName!: string
  controllerName!: string

  public getControllerName () {
    return this.controllerName ? this.controllerName : this.modelName + 'Controller'
  }

  public getPermissionName (name: string) {
    return name.includes('@') ? name : this.getControllerName() + '@' + name
  }

  public getOptions (fieldName: string) {
    return ConstService.getOptions(this.modelName, fieldName)
  }

  // eslint-disable-next-line
  public getOptionsValue (fieldName: string, value: number, _display_name?: string) {
    const options = ConstService.getOptions(this.modelName, fieldName)
    const item = options.find((res) => res.value === value)
    return item ? item.value : 0
  }

  public getOptionsLabel (fieldName: string, value: number) {
    const options = ConstService.getOptions(this.modelName, fieldName)
    const item = options.find((res) => res.value === value)
    return item ? item.display_name : ''
  }

  // eslint-disable-next-line
  public getStatusValue (value: number, _display_name?: string) {
    return this.getOptionsValue('status', value, _display_name)
  }

  public getStatusLabel (value: number) {
    return this.getOptionsLabel('status', value)
  }
}
