
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    deep: {
      type: Number,
      default: 3
    }
  },
  watch: {
    '$route' () {
      if (this.$route.matched[this.deep - 1]) {
        this.DataRenderElement.reload()
      }
    }
  },
  computed: {
    DataRenderElement (): any {
      return this.$refs.DataRenderElement
    }
  }
})
