import RouterService from 'pk/service/RouterService'
import ModalAbstract from './ModalAbstract'

interface ListItem {
  path: string;
  services: ModalAbstract[];
}

class PagePopupService {
  private list: ListItem[] = []

  public add (service: ModalAbstract) {
    const listItem = this.list.find((res) => res.path === RouterService.getPath())
    if (listItem) {
      listItem.services.push(service)
    }
  }

  public del (service: ModalAbstract) {
    const listItem = this.list.find((res) => res.path === RouterService.getPath())
    if (listItem) {
      listItem.services = listItem.services.filter((res) => res.id !== service.id)
    }
  }

  public clear () {
    const listItem = this.list.find((res) => res.path === RouterService.getPath())
    if (listItem) {
      listItem.services.forEach((res) => {
        res.destroy()
      })
      listItem.services = []
    }
  }
}

export default new PagePopupService()
