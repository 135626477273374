
import { PromiseResult } from 'pk/service/IOService'
import { defineComponent } from 'vue'
import UIService from 'pk/service/UIService'

export default defineComponent({
  inheritAttrs: false,
  props: {
    click: {
      type: Function,
      default: () => Promise.resolve()
    },
    clickAfter: {
      type: Function,
      default: () => Promise.resolve()
    },
    type: {
      type: String,
      default: 'warning'
    },
    title: {
      type: String,
      default: '您真的要删除吗？'
    }
  },
  data () {
    return {
      loading: false,
      visible: false
    }
  },
  methods: {
    handleClick () {
      if (this.loading) return
      this.loading = true
      this.click()
        .then((res: PromiseResult) => {
          if (res && res.message) {
            UIService.toast.success(res.message)
          }
          this.handleloaded()
          return this.clickAfter()
        })
        .catch((res: PromiseResult) => {
          if (res && res.message) {
            UIService.toast.error(res.message)
          }
          this.handleloaded()
        })
    },
    handleloaded () {
      this.visible = false
      setTimeout(() => {
        this.loading = false
      }, 1000)
    }
  }
})
