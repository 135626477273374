import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CoverImage = _resolveComponent("CoverImage")!
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_ModalContainer = _resolveComponent("ModalContainer")!

  return (_openBlock(), _createBlock(_component_ModalContainer, {
    customClass: "ModalCarousel",
    modalService: _ctx.modalService
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_carousel, {
        interval: 4000,
        "initial-index": _ctx.index,
        autoplay: _ctx.autoplay,
        onChange: _ctx.handleChange,
        height: _ctx.height
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (url, index) => {
            return (_openBlock(), _createBlock(_component_el_carousel_item, { key: index }, {
              default: _withCtx(() => [
                _createVNode(_component_CoverImage, {
                  src: url,
                  ref_for: true,
                  ref: "imgs",
                  zip: "720",
                  onLoad: ($event: any) => (_ctx.handleImgLoad(index))
                }, null, 8, ["src", "onLoad"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["initial-index", "autoplay", "onChange", "height"])
    ]),
    _: 1
  }, 8, ["modalService"]))
}