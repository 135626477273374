import IOService from 'pk/service/IOService'
import BaseAbstract from 'pk/abstract/BaseAbstract'

class SdDemandService extends BaseAbstract {
  modelName = 'SdDemand'
  displayName = '需求'
  basePath = '/sd/demand'

  index (params: any) {
    return IOService.get('sd_demand', params)
  }

  store (params: { is_push: number; industry: number[]; city: number }) {
    return IOService.post('sd_demand', params)
  }

  show (id: number) {
    return IOService.get(`sd_demand/${id}`)
  }

  update (params: { id: string; is_push: number; industry: number[]; city: number }) {
    return IOService.put(`sd_demand/${params.id}`, params)
  }

  destroy (id: string) {
    return IOService.delete(`sd_demand/${id}`)
  }
}

export default new SdDemandService()
