
import { ITableColumnAction } from 'pk/interface/common'
import { defineComponent } from 'vue'
import TableMixins from './TableMixins'

export default defineComponent({
  mixins: [TableMixins],
  props: {
    buttons: {
      type: Array as () => ITableColumnAction[]
    },
    fixed: {
      type: String,
      default: undefined
    }
  },
  methods: {
    handleRefresh () {
      return this.tableService?.refresh()
    }
  }
})
