
import ConstService, { OptionBoolItem, OptionItem } from 'pk/service/ConstService'
import { getDeepValue } from 'pk/service/ToolsService'
import { defineComponent } from 'vue'
import TableMixins from './TableMixins'

export default defineComponent({
  mixins: [TableMixins],
  props: {
    bool: Boolean,
    colors: Object,
    options: {
      type: Array as () => OptionItem[]
    }
  },
  data () {
    return {
      innerOptions: [] as (OptionItem | OptionBoolItem)[]
    }
  },
  created () {
    if (this.options) {
      this.innerOptions = JSON.parse(JSON.stringify(this.options))
    } else {
      if (this.bool) {
        this.innerOptions = ConstService.getBoolOptions()
      } else {
        if (this.tableService) {
          this.innerOptions = this.tableService.infoService.getOptions(this.prop)
        } else {
          this.innerOptions = ConstService.getOptions('', this.prop)
        }
      }
    }
  },
  methods: {
    getValue (row: any) {
      let value = getDeepValue(this.prop, row)
      value = this.bool && !value ? 0 : value
      const item = this.innerOptions.find((res) => res[this.props.value] === value)
      return item ? item[this.props.label] : value
    },
    getColor (row: any) {
      let value = getDeepValue(this.prop, row)
      value = this.bool && !value ? 0 : value
      const item = this.innerOptions.find((res) => res[this.props.value] === value)
      if (item && item.color) {
        return ConstService.getColor(item.color)
      }
      if (this.bool) {
        const innerColors = this.colors || { 1: 'success', 0: 'warning' }
        if (!innerColors[value]) {
          console.warn('该字段可能不是布尔型')
        }
        return ConstService.getColor(innerColors[value])
      } else {
        if (this.colors && this.colors[value]) {
          return ConstService.getColor(this.colors[value])
        }
      }
      return ConstService.getColor('')
    }
  }
})
