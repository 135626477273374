
import { getAncestorsAndSelf, getCityName, getDeepValue } from 'pk/service/ToolsService'
import { defineComponent } from 'vue'
import TableMixins from './TableMixins'

export default defineComponent({
  mixins: [TableMixins],
  props: {
    type: String,
    options: Array
  },
  methods: {
    getValue (row: any) {
      const value = getDeepValue(this.prop, row)
      if (value) {
        if (this.type === 'city') {
          return getCityName(value)
        } else {
          const paths = getAncestorsAndSelf(value, this.options as any[], this.props.value)
          return paths.map((res: any) => res[this.props.label]).join('/')
        }
      } else {
        return '--'
      }
    }
  }
})
