
import { defineComponent } from 'vue'
import { resolveImageUrl } from 'pk/service/ToolsService'

export default defineComponent({
  props: {
    src: String,
    zip: String,
    fit: {
      type: String,
      default: 'cover'
    },
    width: String,
    height: String
  },
  computed: {
    styles () {
      return {
        width: this.width,
        height: this.height
      }
    }
  },
  data () {
    return {
      innerSrc: resolveImageUrl(this.src as string, this.zip as string)
    }
  },
  methods: {
    handleLoad (e: Event) {
      this.$emit('load', e)
    }
  }
})
