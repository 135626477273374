import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tree = _resolveComponent("el-tree")!

  return (_openBlock(), _createBlock(_component_el_tree, {
    data: _ctx.treeList,
    "node-key": _ctx.nodeKey,
    "show-checkbox": "",
    ref: "treeElement",
    props: { label: 'display_name' },
    class: _normalizeClass(["FormTree", [_ctx.maxDeep > 3 ? 'deep4' : 'deep3']]),
    "check-strictly": _ctx.checkStrictly
  }, null, 8, ["data", "node-key", "check-strictly", "class"]))
}