import BaseAbstract from 'pk/abstract/BaseAbstract'
import { PromiseResult } from 'pk/service/IOService'
import RouterService from 'pk/service/RouterService'
import ModalAbstract from '../Modal/ModalAbstract'

export interface InfoService extends BaseAbstract {
  show: Function;
  update: Function;
  store: Function;
}

export default class FormFactory {
  private onSubmit?: Function
  private onAfterSubmit!: Function
  private onLoad?: Function
  private onAfterLoad!: Function
  private onBeforeLoad!: Function
  public infoService?: InfoService
  public modalService?: ModalAbstract
  public validateField: Function

  public form = {
    id: 0
  } as {
    [key: string]: any
  }

  constructor ({
    form,
    onSubmit,
    onAfterSubmit,
    onLoad,
    onAfterLoad,
    onBeforeLoad,
    infoService,
    modalService,
    validateField
  } = {} as {
    form: Object,
    onSubmit?: Function,
    onAfterSubmit: Function,
    onLoad?: Function,
    onAfterLoad: Function,
    onBeforeLoad: Function,
    infoService?: InfoService,
    modalService?: ModalAbstract,
    validateField: Function
  }) {
    this.form = form
    this.onSubmit = onSubmit
    this.onAfterSubmit = onAfterSubmit
    this.onLoad = onLoad
    this.onAfterLoad = onAfterLoad
    this.onBeforeLoad = onBeforeLoad
    this.infoService = infoService
    this.modalService = modalService
    this.validateField = validateField
  }

  public load () {
    return this.onBeforeLoad()
      .then(() => {
        if (this.onLoad) {
          return this.onLoad()
        } else {
          if (this.form.id && this.infoService && this.infoService.show) {
            return this.infoService.show(this.form.id)
              .then((res: PromiseResult) => {
                Object.keys(this.form).forEach((key: string) => {
                  if (!(res.data[key] === null || res.data[key] === undefined)) {
                    this.form[key] = res.data[key]
                  }
                })
                return res
              })
          }
        }
      })
      .then((res: PromiseResult) => this.onAfterLoad(res))
  }

  public submit () {
    return Promise.resolve()
      .then(() => {
        if (this.onSubmit) {
          return this.onSubmit()
        } else {
          if (this.infoService) {
            if (this.form.id) {
              return this.infoService.update(this.form)
            } else {
              return this.infoService.store({
                ...this.form,
                marking: RouterService.query('marking')
              })
            }
          }
        }
      })
      .then((res) => this.onAfterSubmit(res))
  }

  public submitAndBack () {
    return this.submit()
      .then((res) => {
        if (this.modalService) {
          this.modalService.success(res)
        } else {
          RouterService.go()
        }
      })
  }
}
